import React, { useRef } from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'
import Introduction from '../components/Home/Introduction'
import CoreValues from '../components/Home/CoreValues'
import AboutUs from '../components/Home/AboutUs'
import Services from '../components/Home/Services'
import OurTeam from '../components/Home/OurTeam'
// import OurClients from '../components/Home/OurClients'
// import ContactForm from "../components/Global/ContactForm"
import Faqs from "../components/Global/Faqs"

import WorkProcess from '../components/Home/WorkProcess'
import Laboratory from '../components/Home/Laboratory'
import PatientLink from '../components/Home/PatientLink'

function HomeTemplate(props) {
    // console.log(props)
    let data = props.data.wpgraphql;


    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://plusmedical.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://plusmedical.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }


    return (
        <Layout
            headerMenu={props.pageContext.headerMenu}
            footerMenu={props.pageContext.footerMenu}
            recentPosts={data.posts}
            currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
            availableVersions={props.pageContext.availablePages}
            metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}
            services={props.pageContext.servicesCategories}
            examinations={props.pageContext.examinationsCategories}
            preHeader={data.template.contactInformationTemplate}
        >
            <div>
                <section>
                    <Introduction data={data.page.homePageAcf} lang={props.pageContext.language} />
                </section>
                <section>
                    <PatientLink data={data.page.homePageAcf.patientLink} />
                </section>
                <section>
                    <CoreValues data={data.page.homePageAcf.coreValue} />
                </section>
                <section style={{ backgroundColor: "#F8F9FA" }}>
                    <AboutUs data={data.page.homePageAcf.whoWeAre} lang={props.pageContext.language} />
                </section>
                <section>
                    <Services
                        data={{
                            title: data.page.homePageAcf.servicesTitle,
                            services: data.serviceCategories.edges,
                            sectionName: data.page.homePageAcf.servicesSectionName,
                            servicesDescription: data.page.homePageAcf.servicesDescription,
                            servicesReadMore: data.page.homePageAcf.servicesReadMore
                        }}
                    />
                </section>
                <section>
                    <Services
                        data={{
                            title: data.page.homePageAcf.examinationsTitle,
                            services: data.examinationCategories.edges,
                            sectionName: data.page.homePageAcf.examinationsSectionName,
                            servicesDescription: data.page.homePageAcf.examinationsDescription,
                            servicesReadMore: data.page.homePageAcf.examinationsReadMore
                        }}
                    />
                </section>
                <section>
                    <WorkProcess
                        data={data.page.homePageAcf.workProcess}
                    />
                </section>

                <section>
                    <Laboratory data={data.page.homePageAcf.labGroup} />
                </section>

                <section>
                    <OurTeam
                        data={{
                            title: data.page.homePageAcf.ourTeamHeader,
                            subtitle: data.page.homePageAcf.ourTeamSectionName,
                            par: data.page.homePageAcf.ourTeamParagraph,
                            cta: data.page.homePageAcf.ourTeamCta,
                            members: data.teamMembers.edges,
                            lang: props.pageContext.language
                        }} />
                </section>

                <section>
                    {(data.page.faqsAcf.faqs && data.page.faqsAcf.faqs.faqs && data.page.faqsAcf.faqs.faqs.length > 0) && (
                        <Faqs
                            data={data.page.faqsAcf.faqs}
                        />
                    )}
                </section>

                {/* <section>
                    <OurClients data={{ title: data.page.homePageAcf.ourClientsTitle, clients: data.clients.edges }} />
                </section>

                <section>
                    <ContactForm
                        form={data.template.contactInformationTemplate.form}
                        responses={data.template.contactInformationTemplate.responseMessages}
                        endPoint={"contactEmail"}
                        language={props.pageContext.language}
                        subjectRequired={true}
                        messageRequired={true}
                    />
                </section> */}
            </div>
        </Layout>
    )
}

export default HomeTemplate

export const pageQuery = graphql`query GET_PAGE($id: ID!, $language: WPGraphQL_LanguageCodeFilterEnum, $themeTemplateUri: ID!) {
  wpgraphql {
    page(id: $id) {
      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
      homePageAcf {
        ...IntroductionFragment
        coreValue {
          ...CoreValueFragment
        }
        patientLink {
            title
            content
            cta {
                text
                link
            }
            image {
                sourceUrl
                imageFile {
                childImageSharp {
                    gatsbyImageData(
                        width: 500
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
                }
            }
        }
        ...AboutUsFragment
        servicesTitle
        servicesSectionName
        servicesDescription
        servicesReadMore
        examinationsTitle
        examinationsSectionName
        examinationsDescription
        examinationsReadMore
        ourTeamHeader
        ourTeamSectionName
        ourTeamParagraph
        ourTeamCta
        workProcess {
          sectionName
          title
          description
          workProcesses {
            title
            description
            icon {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  gatsbyImageData(width: 37, quality: 100, placeholder: NONE, layout: FIXED)
                }
              }
            }
          }
        }
        labGroup {
          title
          description
          image {
            altText
            sourceUrl
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  width: 870
                  quality: 100
                  placeholder: NONE
                  layout: CONSTRAINED
                )
              }
            }
          }
          carousel {
            image {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  gatsbyImageData(width: 150, quality: 100, placeholder: NONE, layout: FIXED)
                }
              }
            }
          }
        }
      }
      faqsAcf {
        faqs {
          title
          faqs {
            question
            answer
            visibleInSite
          }
        }
      }
    }
    serviceCategories(where: {language: $language}) {
      edges {
        node {
          name
          services(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
            edges {
              node {
                uri
                title
                excerpt
                servicesAcf {
                  smallIcon {
                    altText
                    sourceUrl
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(width: 40, placeholder: NONE, layout: FIXED)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    examinationCategories(where: {language: $language}) {
      edges {
        node {
          name
          examinations(first: 200, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
            edges {
              node {
                uri
                title
                excerpt
                servicesAcf {
                  smallIcon {
                    altText
                    sourceUrl
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(width: 40, placeholder: NONE, layout: FIXED)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    teamMembers(
      first: 4
      where: {language: $language, orderby: {field: MENU_ORDER, order: ASC}}
    ) {
      ...OurTeamFragment
    }
    clients(where: {language: $language}) {
      ...ClientsFragment
    }
    template(id: $themeTemplateUri, idType: SLUG) {
      contactInformationTemplate {
        telephoneNumber
        supportEmail
        hoursLabel
        hoursTitle
        form {
          title
          subTitle
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    posts(
      first: 2
      where: {language: $language, orderby: {field: DATE, order: ASC}}
    ) {
      ...RecentPostsFragment
    }
  }
}
`
