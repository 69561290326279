import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import "./CoreValues.css";


function CoreValues(props) {
    const data = props.data;

    return (
        <div className="flex flex-wrap core-values">
            {data.map((value, index) => (
                <div className="w-full md:w-1/3 flex px-8 py-8">
                    <div style={{width:"50px"}}>
                        <GatsbyImage
                            image={value.coreValueBackground.imageFile.childImageSharp.gatsbyImageData}
                            alt={value.coreValueBackground.alt}
                            style={{width:"50px"}} />
                    </div>
                    <div className="cv-content pl-4" dangerouslySetInnerHTML={{ __html: value.coreValueDescription }} />
                </div>
            ))}
        </div>
    );
}

export default CoreValues;

export const query = graphql`fragment CoreValueFragment on WPGraphQL_Page_Homepageacf_coreValue {
  coreValueDescription
  coreValueBackground {
    sourceUrl
    imageFile {
      childImageSharp {
        gatsbyImageData(
          width: 64
          quality: 70
          placeholder: NONE
          layout: CONSTRAINED
        )
      }
    }
  }
}
`