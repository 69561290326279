import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import htmlDecode from "html-entities-decoder"

import "./Services.css";


function Services(props) {
    const data = props.data;
    return (
        <div className="pt-16 md:pt-32 m-auto text-center" style={props.stylez ? props.stylez : { display: "block" }}>
            {data.title && (
                <>
                    <p className="section-name">{data.sectionName}</p>
                    <h2 className="" >
                        {data.title}
                    </h2>
                    <p className="m-auto mt-4" style={{ maxWidth: "500px" }}>{data.servicesDescription}</p>
                </>
            )}
            {props.data.services.map((services, index) => (
                <div key={`services-${services.node.name}`} style={index === 1 ? { backgroundColor: "#f8f9fa" } : { backgroundColor: "#ffffff" }} className="py-8 md:py-16 text-left" >
                    <div className="container m-auto">
                        <div className="flex flex-wrap py-4">
                            {services.node.services && services.node.services.edges.map((service, yndex) => (
                                <div key={`service-${service.node.title}`} className="w-full md:w-1/3 flex relative">
                                    <Link to={`${service.node.uri}`} className="m-8 m-1 service-tab relative" style={{ backgroundColor: "#f6faff" }} >
                                        <div className="small-icon-cont">
                                            <GatsbyImage
                                                image={service.node.servicesAcf.smallIcon.imageFile.childImageSharp.gatsbyImageData}
                                                alt={service.node.servicesAcf.smallIcon.altText}
                                                className="small-icon m-auto" />
                                        </div>
                                        {data.title ?
                                            <h3 className="text-2xl py-2 services-h4 px-6 service-h4">
                                                {htmlDecode(service.node.title)}
                                            </h3>
                                            :
                                            <h3 className="text-2xl py-4 px-6 service-h4">
                                                {htmlDecode(service.node.title)}
                                            </h3>
                                        }
                                        <div dangerouslySetInnerHTML={{ __html: service.node.excerpt }} className="px-6" />
                                        <div className="px-6 pb-12 pt-4">
                                            <span className="service-read-more" style={{ position: "absolute", bottom: "20px" }}>
                                                {data.servicesReadMore}
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            ))}

                            {services.node.examinations && services.node.examinations.edges.map((service, yndex) => (
                                <div key={`service-${service.node.title}`} className="w-full md:w-1/3 flex relative">
                                    <Link to={`${service.node.uri}`} className="m-8 m-1 service-tab relative" style={{ backgroundColor: "#f6faff" }} >
                                        <div className="small-icon-cont">
                                            <GatsbyImage
                                                image={service.node.servicesAcf.smallIcon.imageFile.childImageSharp.gatsbyImageData}
                                                alt={service.node.servicesAcf.smallIcon.altText}
                                                className="small-icon m-auto" />
                                        </div>
                                        {data.title ?
                                            <h3 className="text-2xl py-2 services-h4 px-6 service-h4">
                                                {htmlDecode(service.node.title)}
                                            </h3>
                                            :
                                            <h3 className="text-2xl py-4 px-6 service-h4">
                                                {htmlDecode(service.node.title)}
                                            </h3>
                                        }
                                        <div dangerouslySetInnerHTML={{ __html: service.node.excerpt }} className="px-6" />
                                        <div className="px-6 pb-12 pt-4">
                                            <span className="service-read-more" style={{ position: "absolute", bottom: "20px" }}>
                                                {data.servicesReadMore}
                                            </span>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Services;
