import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import "./Introduction.css";


function AboutUs(props) {
    const data = props.data;

    return (
        <div style={{ display: "grid" }}>
            {/* You can use a GatsbyImage component if the image is dynamic */}
            <GatsbyImage
                style={{
                    gridArea: "1/1",
                    // You can set a maximum height for the image, if you wish.
                }}
                className="gph"
                imgClassName="hidden md:block"
                alt={data.image.altText}
                loading={"lazy"}
                image={data.image.imageFile.childImageSharp.gatsbyImageData}
                formats={["auto", "webp", "avif"]}
            />
            <div
                style={{
                    // By using the same grid area for both, they are stacked on top of each other
                    gridArea: "1/1",
                    position: "relative",
                    // This centers the other elements inside the hero component
                    placeItems: "center",
                    display: "grid",
                }}
            >
                {/* Any content here will be centered in the component */}
                <div className="py-16 md:py-32 m-auto container flex flex-wrap text-center px-4 md:px-0">
                    <div className="w-1/2 hidden md:block">

                    </div>
                    <div className="w-full md:w-1/2 flex flex-wrap text-center md:text-left items-center">
                        <div className="w-full main-color text-4xl">
                            <p className="section-name">{data.sectionName}</p>
                            <h2 className="font-semibold">
                                {data.title}
                            </h2>
                        </div>

                        <p className="py-4 w-full">
                            {data.content}
                        </p>

                        <div className="flex flex-wrap text-left">
                            {data.contentRepeater && data.contentRepeater.length > 0 && data.contentRepeater.map((dt, i) => (
                                <div className="w-full md:w-1/2 ab-rep-div" key={`ab-repeater-${i}`}>
                                    <div className="flex rep-f">
                                        <GatsbyImage
                                            image={dt.icon.imageFile.childImageSharp.gatsbyImageData}
                                            alt={dt.icon.alt} />
                                        <h3 className="pl-4 ab-h3">{dt.title}</h3>
                                    </div>
                                    <div className="ab-rep-editor" dangerouslySetInnerHTML={{ __html: dt.content }} />
                                </div>
                            ))}
                        </div>

                        <div className="w-full flex justify-center">
                            <div className="main-btn-container">
                                <Link to={props.lang.toLowerCase() === "el" ? "/services" : `/${props.lang.toLowerCase()}/services`} className="main-button-blue mt-4 md:mt-0">
                                    {data.button}
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;

export const query = graphql`fragment AboutUsFragment on WPGraphQL_Page_Homepageacf {
  whoWeAre {
    sectionName
    title
    content
    subtitle
    button
    contentRepeater {
      title
      content
      icon {
        altText
        sourceUrl
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 40, quality: 100, placeholder: NONE, layout: FIXED)
          }
        }
      }
    }
    image {
      sourceUrl
      altText
      imageFile {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`