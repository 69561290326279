import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"
import htmlDecode from "html-entities-decoder"
import Arrow from "./../../images/arrow-1.png";
import Arrow2 from "./../../images/arrow-2.png";


import "./Services.css";
import "./WorkProcess.css";


function WorkProcess(props) {
    const data = props.data;
    return (
        <div className="pt-16 md:pt-32 m-auto text-center" style={{ backgroundColor: "#f6faff" }}>
            {console.log(data)}
            <p className="section-name">{data.sectionName}</p>
            <h2 className="" >
                {data.title}
            </h2>
            <p className="m-auto mt-4" style={{ maxWidth: "500px" }}>{data.description}</p>

            <div className="py-8 md:py-16 flex flex-wrap container m-auto" >

                {data.workProcesses.map((wp, index) => (
                    <div className="w-full md:w-1/3 flex relative" key={`workProcess-${index}`}>
                        <div className="m-8 m-1 relative" style={{ backgroundColor: "#f6faff" }} >
                            <div className="flex wp-icon-cont">
                                <div className="wp-icon">
                                    <GatsbyImage
                                        image={wp.icon.imageFile.childImageSharp.gatsbyImageData}
                                        alt={wp.icon.altText}
                                        className=" m-auto" />
                                    <span className="wp-number">0{index + 1}</span>
                                </div>
                            </div>
                            <h3 className="text-2xl py-4 px-6 work-h3 pt-8">
                                {htmlDecode(wp.title)}
                            </h3>
                            <p className="px-6">
                                {wp.description}
                            </p>
                            {index < 2 && (
                                <div className="arrow hidden lg:block">
                                    { index % 2 
                                        ?
                                            <img src={Arrow2} />
                                        :
                                            <img src={Arrow} />
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default WorkProcess;
