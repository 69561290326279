import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import "./Introduction.css";


function PatientLink(props) {
    const data = props.data;

    return (
        <div style={{ display: "grid" }}>

            {/* Any content here will be centered in the component */}
            <div className="py-16 md:py-32 m-auto container flex flex-wrap text-center px-4 md:px-0">
                <div className="w-1/2 hidden md:block">
                    <GatsbyImage
                            image={data.image.imageFile.childImageSharp.gatsbyImageData}
                            alt={data.image.alt}
                        />
                </div>
                <div className="w-full md:w-1/2 flex flex-wrap text-center md:text-left items-center">
                    <div className="w-full main-color text-4xl">
                        <h2 className="font-semibold">
                            {data.title}
                        </h2>
                    </div>

                    <div className="py-4 w-full" dangerouslySetInnerHTML={{__html: data.content}} />

                    <div className="flex w-full flex-wrap text-left">
                        {data.cta && data.cta.length > 0 && data.cta.map((dt, i) => (
                            <div className= {`w-full md:w-auto flex justify-center md:justify-start ${i == 1 && "mt-8 md:mt-0"}`} key={`ab-repeater-${i}`}>
                                <div className="main-btn-container">
                                    <a href={dt.link} className={`main-button-blue mt-4 md:mt-0 ${i == 1 && "ml-0 md:ml-4"}`}>
                                        {dt.text}
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default PatientLink;

export const query = graphql`fragment AboutUsFragment on WPGraphQL_Page_Homepageacf {
  whoWeAre {
    sectionName
    title
    content
    subtitle
    button
    contentRepeater {
      title
      content
      icon {
        altText
        sourceUrl
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 40, quality: 100, placeholder: NONE, layout: FIXED)
          }
        }
      }
    }
    image {
      sourceUrl
      altText
      imageFile {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`