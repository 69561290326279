import React, { useRef } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import { useHasBeenVisible } from '../../hooks/useVisibility';


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Laboratory.css";

const settings = {

    responsive: [
        {
            breakpoint: 2800,
            settings: {
                arrows: false,
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 3,
                autoplay: true,
                autoplaySpeed: 2000,
                pauseOnHover: true,
                cssEase: "linear"
            }
        },
        {
            breakpoint: 500,
            settings: {
                arrows: false,
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 2,
                arrows: false,
                slidesToScroll: 2,
                autoplay: true,
                autoplaySpeed: 2000,
                pauseOnHover: true,
                cssEase: "linear"
            }
        },
    ]
};


function Laboratory(props) {
    const data = props.data;
    const labs = useRef();

    const hasScrolledLabs = useHasBeenVisible(labs);


    return (
        <div className="m-auto text-left mb-0 " style={{ backgroundColor: "#f6faff" }}>
            <div className="custom-flex">
                <div className="text-white">
                    <div className="lab-l-tab p-8 pr-8 md:pr-12 ">
                        <h2 className="text-white">{data.title}</h2>
                        <p className="lab-p pt-6 pb-8">{data.description}</p>

                        {/* {hasScrolledLabs ? (
                            <div className="pt-8">
                                <Slider {...settings}>
                                    {data.carousel.map((crl, index) => (
                                        <div key={`crl-${index}`}>
                                            <GatsbyImage
                                                image={crl.image.imageFile.childImageSharp.gatsbyImageData}
                                                alt={crl.image.altText}
                                                className="build-fix-icon" />
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        ) :
                            (
                                <div ref={labs}></div>
                            )
                        } */}
                    </div>
                </div>
                <div style={{ display: "grid" }}>
                    <GatsbyImage
                        style={{
                            gridArea: "1/1",
                        }}
                        alt={data.image.altText}
                        loading={"lazy"}
                        image={data.image.imageFile.childImageSharp.gatsbyImageData}
                        formats={["auto", "webp", "avif"]}
                    />
                    <div
                        style={{
                            // By using the same grid area for both, they are stacked on top of each other
                            gridArea: "1/1",
                            position: "relative",
                            // This centers the other elements inside the hero component
                            placeItems: "center",
                            display: "grid",
                        }}
                    >
                        {/* Any content here will be centered in the component */}
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Laboratory;
