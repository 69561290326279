import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";

import { Link, graphql } from "gatsby"

import { Helmet } from "react-helmet"

import "./Introduction.css";

function Introduction(props) {
    const data = props.data;
    return (

        <div>

            <div style={{ display: "grid" }}>
                {/* You can use a GatsbyImage component if the image is dynamic */}
                <GatsbyImage
                    style={{
                        gridArea: "1/1",
                        // You can set a maximum height for the image, if you wish.
                        maxHeight: 600,
                        minHeight: "70vh"
                    }}
                    // You can optionally force an aspect ratio for the generated image
                    // aspectRatio={3 / 1}
                    // This is a presentational image, so the alt should be an empty string
                    alt={data.introductionBackgroundImage.altText}
                    // Assisi, Perúgia, Itália by Bernardo Ferrari, via Unsplash

                    loading={"eager"}

                    image={data.introductionBackgroundImage.imageFile.childImageSharp.gatsbyImageData}

                    formats={["auto", "webp", "avif"]}
                />
                <div
                    style={{
                        // By using the same grid area for both, they are stacked on top of each other
                        gridArea: "1/1",
                        position: "relative",
                        // This centers the other elements inside the hero component
                        placeItems: "center",
                        display: "grid",
                    }}
                >
                    {/* Any content here will be centered in the component */}
                    <div className="block bg-home-wrapper container">
                        <div className="p-4 w-full sm:w-2/3 m-auto">
                            <div className="px-0 sm:px-12 text-white text-center">
                                <h1 className="home-h1 sm:text-4xl md:text-5xl">{data.introductionHeader}</h1>
                                <p className="home-main-p py-4 text-2xl sm:text-3xl m-auto">{data.introductionParagraph}</p>
                                <div className="flex flex-wrap justify-center">
                                    <div>
                                        <Link to={"/" + data.introductionButtonUrl + "/"} className="home-intro-bttn">{data.introductionButton}</Link>
                                    </div>
                                    <div className="mt-8 lg:mt-0 ml-0 lg:ml-8">
                                        { props.lang === "EL"
                                            ?
                                                <Link to={"/" + "services" + "/"} className="home-intro-bttn">Παρεχόμενες Υπηρεσίες</Link>
                                            :
                                                <Link to={"/en/" + "services" + "/"} className="home-intro-bttn">Services</Link>
                                        }   
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="w-full sm:w-1/3 flex justify-center">
                        </div>
                    </div>
                </div>
            </div>



            {/* <div className="background-home-container flex-wrap relative">
                <div className="home-img-wrapper hidden sm:block" style={{ padding: 0 }}>
                    <picture>
                        <source type="image/webp" srcset={data.introductionBackgroundImage.imageFile.childImageSharp.gatsbyImageData.srcSetWebp} media="(min-width: 601px)" />
                        <source type="image/jpg" srcset={data.introductionBackgroundImage.imageFile.childImageSharp.gatsbyImageData.srcSet} media="(min-width: 601px)" />
                        <img
                            srcSet={data.introductionBackgroundImage.imageFile.childImageSharp.gatsbyImageData.srcSetWebp}
                            style={{ objectFit: "cover", width: "100%", height: "100%" }}
                            alt={data.introductionBackgroundImage.altText}
                        />
                    </picture>
                </div>
                <div className="flex flex-wrap justify-center items-center bg-home-wrapper container">
                    <div className="p-4 w-full sm:w-2/3">
                        <div className="px-0 sm:px-12 text-white text-center sm:text-left">
                            <h1 className="home-h1 sm:text-4xl md:text-5xl">{data.introductionHeader}</h1>
                            <p className="home-main-p py-4 text-2xl sm:text-3xl">{data.introductionParagraph}</p>
                            <Link to={"/" + data.introductionButtonUrl + "/"} className="home-intro-bttn">{data.introductionButton}</Link>
                        </div>
                    </div>
                    <div className="w-full sm:w-1/3 flex justify-center">
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default Introduction;

export const query = graphql`fragment IntroductionFragment on WPGraphQL_Page_Homepageacf {
  introductionHeader
  introductionParagraph
  introductionButton
  introductionButtonUrl
  introductionBackgroundImage {
    altText
    sourceUrl
    imageFile {
        childImageSharp {
            gatsbyImageData(quality: 80, layout: FULL_WIDTH, placeholder: NONE)
        }
    }
  }
}
`